<template>
	<dialog-common ref="dialog" :title="option.title" @ok="confirm">
		<div class="content">

			<div class="number" v-bind:class="{'number-selected':selection==0}">{{value[0]}}</div>
			<div class="number" v-bind:class="{'number-selected':selection==1}">{{value[1]}}</div>
			<div class="text">:</div>
			<div class="number" v-bind:class="{'number-selected':selection==2}">{{value[2]}}</div>
			<div class="number" v-bind:class="{'number-selected':selection==3}">{{value[3]}}</div>

		</div>

		<keyboard @clickNum="clickNum" @clickPoint="clickPoint" @clickDelete="clickDelete"></keyboard>

	</dialog-common>
</template>

<script>
	import DialogCommon from "./dialog-common.vue"
	import Keyboard from "./keyboard.vue"
	export default {
		components:{DialogCommon,Keyboard},
		props:["title"],
		data(){
			return {
				selection:0,
				value:["","","",""],
				option:{
					title:"",
					value:"",
					onConfirm:null
				}
			}
		},
		methods:{
			open(option){
				this.option = option
				this.selection=0
				let hour = parseInt(option.value/256)
				let minute = option.value%256
				this.value = [parseInt(hour/10),hour%10,parseInt(minute/10),minute%10]
				this.$refs.dialog.open()
			},
			close(){
				this.$refs.dialog.close()
			},
			confirm(){
				let hour = parseInt(this.value[0])*10 + parseInt(this.value[1])
				let minute = parseInt(this.value[2])*10 + parseInt(this.value[3])
				this.option.onConfirm(hour*256+minute)
			},
			clickNum(num){
				this.value[this.selection] = num
				this.value = [].concat(this.value)
				if(this.selection<3){
					this.selection++
				}

			},
			clickPoint(){

			},
			clickDelete(){
				this.value[this.selection] = ""
				this.value = [].concat(this.value)
				if(this.selection>0){
					this.selection--
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.content{
		display: flex;
		align-items: center;
		justify-content: center;

		.number{
			width: 40px;
			height: 50px;
			line-height: 50px;
			text-align: center;
			border: 1px solid #DDD;
			margin: 0 5px;
			font-size: 16px;
			border-radius: 5px;
			background: #F5F5F5;
		}
		.text{
			width: 40px;
			text-align: center;
			font-size: 16px;
		}

		.number-selected{
			border: 1px solid #1b7d28;
		}

	}


</style>
