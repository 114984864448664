<template>
    <div class="week week-row" style="justify-content: space-between" v-bind:class="{'item-disable':!timing.enable}">

        <div class="week-row">

            <div class="t-number">{{index}}</div>

            <div class="t-item">
                <div class="t-input clickable" @click="clickTimeOn">{{formatTime(timing.timeOn)}}</div>
                <div class="t-text">开</div>
            </div>

            <div class="t-item">
                <div class="t-input clickable" @click="clickTimeOff">{{formatTime(timing.timeOff)}}</div>
                <div class="t-text">关</div>
            </div>

        </div>

        <div class="week-row">
            <div class="t-text">星期</div>
            <div class="t-input clickable" v-for="(week,index) in timing.weekArr"
                 v-bind:class="{'week-enable':week.enable}"
                 @click="clickWeek(index)">{{week.name}}
            </div>

        </div>

        <img class="checkbox clickable" mode="aspectFit" @click="clickSwitch"
             :src="timing.enable?
             require('../../../assets/device/ic_switch_on.png'):
             require('../../../assets/device/ic_switch_off.png')"
        />


        <dialog-edit-time ref="dialogTime"></dialog-edit-time>


    </div>
</template>

<script>
    import DialogEditTime from "./dialog/dialog-edit-time"

    export default {
        components: {DialogEditTime},
        props: ["iotId", "index", "dataIndex", "value"],
        computed: {
            timing() {
                let value = this.value || 0
                let weekByte = Math.floor(value / this.numOfByte(5))

                return {
                    weekByte,
                    timeOn: Math.floor(value % this.numOfByte(5) / this.numOfByte(3)),
                    timeOff: Math.floor(value % this.numOfByte(3) / this.numOfByte(1)),
                    enable: (value & 0xFF) > 0,
                    weekArr: [
                        {
                            name: "一",
                            enable: (weekByte & 0b1) > 0
                        },
                        {
                            name: "二",
                            enable: (weekByte & 0b10) > 0
                        },
                        {
                            name: "三",
                            enable: (weekByte & 0b100) > 0
                        },
                        {
                            name: "四",
                            enable: (weekByte & 0b1000) > 0
                        },
                        {
                            name: "五",
                            enable: (weekByte & 0b10000) > 0
                        },
                        {
                            name: "六",
                            enable: (weekByte & 0b100000) > 0
                        },
                        {
                            name: "日",
                            enable: (weekByte & 0b1000000) > 0
                        }
                    ]
                }
            }
        },
        methods: {
            formatTime(minute) {
                let hour = parseInt(minute / 256)
                minute = minute % 256
                return ("00" + hour).substr(-2) + ":" + ("00" + minute).substr(-2)
            },
            clickWeek(index) {
                let timing = {...this.timing}
                timing.weekArr[index].enable = !timing.weekArr[index].enable
                this.valueChanged(timing)
            },
            clickSwitch() {
                let timing = {...this.timing}
                timing.enable = !timing.enable
                this.valueChanged(timing)
            },
            clickTimeOn() {
                let self = this
                this.$refs.dialogTime.open({
                    title: "开启时间",
                    value: this.timing.timeOn,
                    onConfirm(value) {
                        let timing = {...self.timing}
                        timing.timeOn = value
                        self.valueChanged(timing)
                    }
                })
            },
            clickTimeOff() {
                let self = this
                this.$refs.dialogTime.open({
                    title: "关闭时间",
                    value: this.timing.timeOff,
                    onConfirm(value) {
                        let timing = {...self.timing}
                        timing.timeOff = value
                        self.valueChanged(timing)
                    }
                })
            },
            valueChanged(timing) {
                let weekByte = 0
                if (timing.weekArr[0].enable) {
                    weekByte = weekByte | 0b1
                }

                if (timing.weekArr[1].enable) {
                    weekByte = weekByte | 0b10
                }

                if (timing.weekArr[2].enable) {
                    weekByte = weekByte | 0b100
                }

                if (timing.weekArr[3].enable) {
                    weekByte = weekByte | 0b1000
                }

                if (timing.weekArr[4].enable) {
                    weekByte = weekByte | 0b10000
                }

                if (timing.weekArr[5].enable) {
                    weekByte = weekByte | 0b100000
                }

                if (timing.weekArr[6].enable) {
                    weekByte = weekByte | 0b1000000
                }

                let value =
                    (weekByte * this.numOfByte(5)) +
                    (timing.timeOn * this.numOfByte(3)) +
                    (timing.timeOff * this.numOfByte(1)) +
                    (timing.enable ? 1 : 0)

                console.log("timing change:" + value)

                this.$emit("onValueChange", this.dataIndex, value)
            },
            numOfByte(b) {
                let num = 1
                for (var i = 0; i < b; i++) {
                    num *= 256
                }
                return num
            }
        }
    }
</script>

<style lang="scss" scoped>

    .week {
        padding: 12px;
        background: #FFF;
        border-bottom: 1px solid #F0F0F0;
    }

    .week-row {
        display: flex;
        align-items: center;
    }

    .item-disable {
        opacity: 0.6;
        background: #EEEEEE;
    }


    .t-item {
        display: flex;
        align-items: center;
        margin-right: 30px;
    }

    .t-text {
        color: #666666;
        margin: 0 10px;
    }

    .t-input {
        background: rgba($color: #CCC, $alpha: 0.5);
        color: #000;
        padding: 5px 15px;
        margin-right: 5px;
        font-size: 14px;
    }

    .week-enable {
        color: #FFF;
        background: #27d85a;
    }

    .t-number {
        width: 25px;
        height: 25px;
        line-height: 25px;
        border-radius: 50%;
        text-align: center;
        color: #FFF;
        background: #27d85a;
        margin-right: 20px;
    }

    .checkbox {
        width: 40px;
    }

</style>
